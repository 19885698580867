<template>
  <div class="new-user-page admin-page">
    <div class="container">
      <AdminHeader />
      <div class="heading">
        <v-breadcrumbs
          class="breadcrumbs"
          divider="<v-icon>mdi-chevron-right</v-icon>"
          :items="[
            {
              text: 'Benutzer',
              to: '/admin/users',
            },
            {
              text: userForm && userForm.name ? userForm.name : 'Benutzer',
              to: $route.path,
            },
          ]"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to == $route.path ? '' : item.to"
              exact
              exact-active-class="active"
              active-class=""
              :class="item.to == $route.path ? 'active' : ''"
            >
              <h2 class="breadcrumbItem" v-if="item.to == $route.path">
                {{ item.text }}
                <v-icon right v-if="valid">{{
                  sync ? 'cloud_done' : 'sync'
                }}</v-icon>
                <v-icon right color="error" v-else>error</v-icon>
              </h2>
              <p class="breadcrumbItem" v-else>{{ item.text }}</p>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </div>

      <v-form
        class="form"
        ref="userForm"
        v-model="valid"
        v-if="userForm && Object.keys(userForm).length && user"
        @input.native="fieldInput"
      >
        <v-text-field
          class="email input"
          outlined
          label="E-Mail"
          placeholder="max.mustermann"
          suffix="@franz…asium.eu"
          v-model="userForm.email"
          :rules="[rules.required]"
          tabindex="1"
        ></v-text-field>

        <v-text-field
          class="name input"
          outlined
          label="Name"
          placeholder="Max Mustermann"
          v-model="userForm.name"
          :rules="[rules.required]"
          tabindex="2"
        ></v-text-field>

        <div class="actionBtns">
          <v-btn
            elevation="0"
            color="primary"
            outlined
            :loading="passwordResetLoading"
            @click="resetPassword()"
          >
            <v-icon left>restore</v-icon>
            Passwort zurücksetzen
          </v-btn>

          <v-btn elevation="0" color="error" @click="deleteUser()">
            <v-icon left>delete</v-icon>
            Benutzer löschen
          </v-btn>
        </div>

        <h3 class="subheading">Berechtigungen</h3>

        <v-switch
          class="admin input"
          label="Administrator"
          v-model="userForm.admin"
          inset
          tabindex="3"
          @change="changeAdmin"
        ></v-switch>

        <v-select
          class="canDebit input"
          :items="campaignItems"
          v-model="userForm.canDebit"
          label="Abbuchung bei"
          multiple
          outlined
          tabindex="4"
          :disabled="userForm.admin"
          :hint="
            userForm.admin
              ? 'Administratoren können bei allen Kampagnen Abbuchungen vornehmen'
              : null
          "
          :persistent-hint="userForm.admin"
          @change="fieldInput"
        ></v-select>
      </v-form>
    </div>

    <v-snackbar v-model="snackbar.active">
      {{ snackbar.message }}
    </v-snackbar>

    <v-dialog v-model="error.active" elevation="10" persistent max-width="500">
      <v-card>
        <template v-if="error.code === 'registerError'">
          <v-card-title style="word-break: break-word;">
            Fehler beim Erstellen des Benutzers
          </v-card-title>
          <v-card-text>
            Als wir versucht haben den Benutzer mit deinen Angaben zu erstellen
            ist folgender Fehler aufgetreten:<br />
            {{ error.reason }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="
                $router.replace({
                  path: `/admin/users`,
                })
              "
              >OK</v-btn
            >
          </v-card-actions>
        </template>
        <template v-if="error.code === 'unknownError'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler beim Erstellen des Benutzers
          </v-card-title>
          <v-card-text>
            Als wir versucht haben den Benutzer mit deinen Angaben zu erstellen
            ist ein unbekannter Fehler aufgetreten<br />
            Fehlercode: {{ error.reason }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="error = { active: false, code: '', reason: null }"
              >OK</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteUserDialog.active" persistent max-width="500px">
      <v-card>
        <v-card-title style="word-break: break-word;">
          Benutzer
          {{
            user && user.name
              ? user.name
              : user && user.email
              ? user.email
              : 'mit unbekanntem Namen'
          }}
          wirklick löschen?
        </v-card-title>
        <v-card-text>
          Bist du sicher, dass du den Benutzer unwiderruflich löschen willst?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteUserDialog.active = false">Abbrechen</v-btn>
          <v-btn
            text
            color="error"
            @click="deleteUser(true)"
            :loading="deleteUserDialog.loading"
            >Löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="error.active" elevation="10" persistent max-width="500">
      <v-card>
        <template v-if="error.code === 'deleteUserError'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler beim Löschen des Benutzers
          </v-card-title>
          <v-card-text>
            Als wir versucht haben den Benutzer mit deinen Angaben zu löschen
            ist ein unbekannter Fehler aufgetreten.<br />
            Bitte versuche es später erneut oder wende dich an den Support.<br />
            Fehlercode: {{ error.catched }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="error = { active: false, code: '', catched: null }"
              >OK</v-btn
            >
          </v-card-actions>
        </template>
        <template v-if="error.code === 'passwordResetError'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler bei der Passwort-Zurücksetzung des Benutzers
          </v-card-title>
          <v-card-text>
            Als wir versucht haben das Passwort des Benutzers zurückzusetzen ist
            ein unbekannter Fehler aufgetreten.<br />
            Bitte versuche es später erneut oder wende dich an den Support.<br />
            Fehlercode: {{ error.catched }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="error = { active: false, code: '', catched: null }"
              >OK</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db, auth } from '@/firebase';
import _ from 'lodash';
import AdminHeader from '@/components/AdminHeader';

export default {
  name: 'AdminUser',
  components: { AdminHeader },
  watch: {
    '$route.params.userid': {
      async handler(id) {
        try {
          let user = await this.$bind('user', db.doc(`users/${id}`));
          if (user == null) {
            this.$router.replace({
              path: '/admin/users',
              query: { error: 'notFound' },
            });
            return;
          }
        } catch (e) {
          console.log(e);
          this.$router.replace({
            path: '/admin/users',
            query: { error: 'unknownDoc', code: e.code },
          });
          return;
        }
      },
      immediate: true,
    },
    user(user) {
      this.userForm = Object.assign(
        {},
        { ...user, email: user && user.email ? user.email.split('@')[0] : '' }
      );
    },
  },
  created() {
    this.debouncedUpdate = _.debounce(this.updateUser, 1500);
  },
  data() {
    return {
      campaigns: null,
      user: null,
      userForm: {
        email: '',
        name: '',
        admin: false,
        canDebit: [],
      },
      rules: {
        required: (value) =>
          (!!String(value).trim().length &&
            (typeof value !== 'number' || !isNaN(value))) ||
          'Dies ist ein Pflichtfeld',
      },
      valid: true,
      passwordResetLoading: false,
      sync: true,
      error: {
        active: false,
        code: '',
        catched: null,
      },
      deleteUserDialog: {
        active: false,
        loading: false,
      },
      snackbar: {
        active: false,
        message: '',
      },
    };
  },
  firestore() {
    return {
      campaigns: db.doc('aggregation/campaigns'),
    };
  },
  computed: {
    campaignItems() {
      if (
        !this.campaigns ||
        !this.campaigns.campaigns ||
        !Array.isArray(this.campaigns.campaigns)
      )
        return [];
      return this.campaigns.campaigns.map((campaign) => ({
        text: campaign.name,
        value: campaign.id,
      }));
    },
  },
  methods: {
    async updateUser() {
      let valid = this.$refs.userForm.validate();
      if (!valid) return;

      let newData = {
        email: this.userForm.email + '@franz-haniel-gymnasium.eu',
        name: this.userForm.name,
        admin: this.userForm.admin,
        canDebit: this.userForm.canDebit,
      };

      this.sync = false;
      await this.$firestoreRefs.user.update(newData);
      this.sync = true;
    },
    fieldInput() {
      this.sync = false;
      this.debouncedUpdate();
    },
    changeAdmin(admin) {
      if (admin) this.userForm.canDebit = [];

      this.updateUser();
    },
    async deleteUser(sure = false) {
      if (!sure) {
        this.deleteUserDialog.active = true;
      } else if (sure === true) {
        this.deleteUserDialog.loading = true;
        try {
          this.deleteUserDialog.active = false;
          await this.$firestoreRefs.user.delete();
          this.$router.replace({
            path: `/admin/users?snackbar=userDeleted`,
          });
        } catch (error) {
          this.deleteUserDialog.active = false;
          this.error.active = true;
          this.error.code = 'deleteUserError';
          this.error.catched = error;
        }
      }
    },
    async resetPassword() {
      // TODO: Custom forgot password email
      try {
        if (!this.user || !Object.keys(this.user).length || !this.user.email)
          throw 'Keine E-Mail für den zu löschenden Benutzer gefunden';
        this.passwordResetLoading = true;

        await auth.sendPasswordResetEmail(this.user.email, {
          url: window.location.origin + '/d',
        });

        this.snackbar = {
          active: true,
          message:
            'Eine E-Mail zur Passwort-Zurücksetzung wurde an den Nutzer geschickt.',
        };
      } catch (error) {
        this.error = {
          active: true,
          code: 'passwordResetError',
          catched: error,
        };
      } finally {
        this.passwordResetLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/admin/admin.scss';

.new-user-page {
  .container {
    .input {
      border-radius: 8px;

      &.email {
        margin-top: 32px;
      }
    }

    .actionBtns {
      margin: 0 0 16px 0;

      & > * {
        margin: 0 24px 16px 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .subheading {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }

    .submitBtn {
      margin: 16px 0 16px 0;
    }
  }
}
</style>
